import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

// 给html 增加一段script
;(() => {
  var width = (document.body && document.body.clientWidth) || (document.documentElement && document.documentElement.clientWidth) || (window.screen && window.screen.availWidth)

  // // 很奇怪 有的手机 获取的width 会很大，这里判断一下 如果大于700，会除以它的devicePixelRatio
  // if (width > 700) {
  //   width = width / window.devicePixelRatio
  // }

  // document.getElementsByTagName('html')[0].style.fontSize = (width / 375) * 100 + 'px'
  let htmlFontSize = width / 1920
  if (htmlFontSize < 0.5) {
    htmlFontSize = 0.5
  }
  document.getElementsByTagName('html')[0].style.fontSize = htmlFontSize + 'px'
})()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
