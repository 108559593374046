import React from 'react';
import styles from './index.module.less'; // 导入CSS模块
import homeDesk from '../../assets/home_desk.png'


function Home() {

    const contentArray = [
        {},
        {
            title: '新技术',
            content: '利用云计算、大数据、人工智能、区块链、GPT大模型应用'
        },
        {
            title: '新模式',
            content: '以医患联动为核心，通过数字化手段实现医疗服务产业升级'
        },
        {
            title: '全链条',
            content: '医生服务、患者管理、医药电商、学术推广、临床转化'
        },
        {
            title: '全赋能',
            content: '工具赋能、资质赋能、供应链赋能、学术赋能，到资本赋能'
        },
        {}
    ];
    return (
        <div className={styles.container}>
            <div className={styles.topColumn}>
                <div className={styles.leftColumn}>
                    <div>让医疗健康服务更智能</div>
                    <p>让国人都能拥有自己的私人医生，践行技术赋能医疗普惠的健康中国梦。</p>
                </div>
                <div className={styles.rightColumn}>
                    <img src={homeDesk} alt="医疗健康服务图片" />
                </div>
            </div>
            <div className={styles.bottomColumn}>
                {contentArray.map((item, index) => {
                    // 根据索引设置不同的背景颜色
                    const boxStyle = {
                        background: index % 2 === 1 ? '#353F58' : '#3E4A66',
                    };
                    return item.title ?
                        <div className={styles.bottomItem} key={index} style={boxStyle}>
                            <div className={styles.bottomItemTitle}>{item.title}</div>
                            <div className={styles.bottomItemContent}>{item.content}</div>
                        </div>
                        :
                        <div className={styles.emptyBottomItem} key={index} style={boxStyle}></div>
                })
                }
            </div>
        </div>
    );
}

export default Home;
