import React from 'react'
import styles from './index.module.less' // 导入CSS模块
import about_qualifications from '../../assets/about_qualifications.png'
import about_cooperate from '../../assets/about_cooperate2.jpg'

function About() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>关于良医健康&商务合作</div>

        <div className={styles.paragraph}>
          <div className={styles.paragraph_title}>关于良医：</div>
          <div className={styles.paragraph_p}>
            良医健康医疗科技（辽宁）有限公司，注册资金1000万，是⼀家专业从事医疗服务智能化的成长型技术公司，公司拥有完整的产品研发管理体系、平台运营管理体系，十余项自主知识产权，其中多项人工智能小模型算法发明。
          </div>
          <div className={styles.paragraph_p}>
            公司致力于通过云计算、大数据、人工智能、区块链等新技术，结合创新的市场模式，围绕医患服务打造全链条、全闭环的赋能型智慧医疗服务生态平台，旗下良医互联网医院，拥有完整的医疗经营资质。
          </div>
          <div className={styles.paragraph_p}>公司通过技术赋能、医疗普惠的核心理念，不忘初心，践行健康中国梦。</div>
        </div>

        <img className={styles.img_qualifications} src={about_qualifications} />
      </div>
      <div className={styles.img_cooperate_box}>
        <img className={styles.img_cooperate} src={about_cooperate} />
        <div className={styles.text_wrap}>
          <div
            style={{
              fontSize: 47,
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            商务合作
          </div>
          <div
            style={{
              fontSize: 27,
            }}>
            zhouliyuan@toheal.cn
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
