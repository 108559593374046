import React from 'react'
import styles from './index.module.less' // 导入CSS模块
function Footer() {
  return (
    <div className={styles.footer}>
      良医健康医疗科技（辽宁）有限公司 © All Rights Reserved.
      &nbsp;
      <span
        onClick={() => {
          window.open('https://beian.miit.gov.cn')
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        辽ICP备18001810号-2
      </span>{' '}
      丨 客服电话：024-22806888
    </div>
  )
}

export default Footer
