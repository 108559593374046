import React, { useMemo } from 'react'
import { span, useLocation, useNavigate } from 'react-router-dom'
import styles from './index.module.less' // 导入CSS模块
import logo from '../../assets/logo@2x.png'
import logo2 from '../../assets/logo2@2x.png'
import classNames from 'classnames'

function Header() {
  const location = useLocation()
  const navigate = useNavigate()
  let headerStyle = location.pathname === '/doctor-service' || location.pathname === '/doctor-group'

  let commonLinkClass = useMemo(() => {
    let arr = [styles.link]
    if (['/', '/digital-marketing', '/about'].includes(location.pathname)) {
      arr.push(styles.white)
    }
    return arr
  }, [location.pathname])

  function handleGo(url) {
    navigate(url)
  }

  return (
    <header className={`${styles.header} ${headerStyle && styles.header2}`}>
      <div className={styles.logo}>
        <img src={headerStyle ? logo2 : logo} />
      </div>
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <span className={classNames(...commonLinkClass, location.pathname === '/' && styles.activeLink)} onClick={() => handleGo('/')}>
            首页
          </span>
        </li>
        <li className={styles.menuItem}>
          <span className={classNames(...commonLinkClass, location.pathname === '/doctor-service' && styles.activeLink)} onClick={() => handleGo('/doctor-service')}>
            医生服务
          </span>
        </li>
        <li className={styles.menuItem}>
          <span className={classNames(...commonLinkClass, location.pathname === '/doctor-group' && styles.activeLink)} onClick={() => handleGo('/doctor-group')}>
            医生集团
          </span>
        </li>
        <li className={styles.menuItem}>
          <span className={classNames(...commonLinkClass, location.pathname === '/digital-marketing' && styles.activeLink)} onClick={() => handleGo('/digital-marketing')}>
            数字化营销
          </span>
        </li>
        <li className={styles.menuItem}>
          <span className={classNames(...commonLinkClass, location.pathname === '/about' && styles.activeLink)} onClick={() => handleGo('/about')}>
            关于良医健康
          </span>
        </li>
      </ul>
    </header>
  )
}

export default Header
