import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import DoctorService from './pages/DoctorService';
import DoctorGroup from './pages/DoctorGroup';
import DigitalMarketing from './pages/DigitalMarketing';
import About from './pages/About';
import Footer from './components/Footer';
import './App.css'

function App() {
  return (
    <Router>
      <div id="app_div">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/doctor-service" element={<DoctorService />} />
          <Route path="/doctor-group" element={<DoctorGroup />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
