import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.less'; // 导入CSS模块
import DigitalMarketingImg1 from '../../assets/DigitalMarketingImg1.png'
import DigitalMarketingImg2 from '../../assets/DigitalMarketingImg2.png'
import DigitalMarketingImg3 from '../../assets/DigitalMarketingImg3.png'
import DigitalMarketingImg4 from '../../assets/DigitalMarketingImg4.png'
const contentArray = [
    {
        title: '良医伴侣',
        content: '私域运营体系',
        info: 'IP矩阵运营丨内容制作输出丨私域用户转化',
        img: DigitalMarketingImg1
    },
    {
        title: '良医严选',
        content: '专业医生推荐的健康商城',
        info: '定制性丨合规性丨营销性',
        img: DigitalMarketingImg2
    },
    {
        title: '良医知道',
        content: '数字化学术推广',
        info: '公益性丨科普性丨学术性',
        img: DigitalMarketingImg3
    },
    {
        title: '良医互联网医院',
        content: '处方流转',
        info: '私域性丨功能性丨推广性',
        img: DigitalMarketingImg4
    },
];
function DigitalMarketing() {
    const navigate = useNavigate();
    const moreClick = () => {
        navigate('/about');
    };
    return (
        <div className={styles.container}>
            <div className={styles.topColumn}>
                <div>数字化营销</div>
                <span style={{ minWidth: '880rem'}}>内容运营+患者教育+私域转化+处方流转+产品交付</span>
                <button className={styles.findMore} onClick={moreClick}>{`了解更多>`}</button>
            </div>
            <div className={styles.bottomColumn}>
                {contentArray.map((item, index) => (
                    <div className={styles.bottomItem} key={index}>
                        <img src={item.img} />
                        <div className={styles.bottomItemText}>
                            <div className={styles.bottomItemTitle}>{item.title}</div>
                            <div className={styles.bottomItemContent}>{item.content}</div>
                            <div className={styles.bottomItemInfo}>{item.info}</div>
                        </div>
                    </div>
                ))
                }
            </div>
        </div>
    );
}

export default DigitalMarketing;
