import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.less'; // 导入CSS模块
import check from '../../assets/check.png'
const contentArray = [
    {
        title: '工具赋能',
        content: '数字化诊室',
        type: '专属'
    },
    {
        title: '资质赋能',
        content: '互联网医院',
        type: '私域'
    },
    {
        title: '交付赋能',
        content: '个性化药房',
        type: '自主'
    },
    {
        title: '产品赋能',
        content: '定制供应链',
        type: '定制'
    },
    {
        title: '财税赋能',
        content: '自动代完税',
        type: '合规'
    }
];
function DoctorGroup() {
    const navigate = useNavigate();
    const moreClick = () => {
        navigate('/about');
    };
    return (
        <div className={styles.container}>
            <div className={styles.topColumn}>
                <div style={{ minWidth: '800rem'}}>良医健康医疗服务团队</div>
                <span style={{ color: '#1D7BFF', padding: '30rem 0 15rem 0',minWidth: '760rem' }}>我是医生团队（集团），缺少商业化运营方案</span>
                <span style={{ minWidth: '760rem'}}>良医健康为您提供全方位赋能方案</span>
                <button className={styles.findMore} onClick={moreClick}>{`了解更多>`}</button>
            </div>
            <div className={styles.bottomColumn}>
                {contentArray.map((item, index) => (
                    <div className={styles.bottomItem} key={index}>
                        <img src={check} />
                        <div className={styles.bottomItemTitle}>{item.title}</div>
                        <div className={styles.bottomItemContent}>{item.content}</div>
                        <div className={styles.bottomItemType}>{item.type}</div>
                    </div>
                ))
                }
            </div>
        </div>
    );
}

export default DoctorGroup;
