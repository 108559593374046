import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.less'; // 导入CSS模块
import doctorServiceImg from '../../assets/doctor_service.png'
function DoctorService() {
    const navigate = useNavigate();
    const moreClick = () => {
        navigate('/about');
    };
    return (
        <div className={styles.container}>
            <div className={styles.topColumn}>
                <div className={styles.leftColumn}>
                    <div className={styles.columnTitle}>良医健康医疗服务团队</div>
                    <div style={{color:'#1D7BFF'}}>我是医生，缺少流量</div>
                    <div className={styles.columnInfo}>加入良医健康医生服务集团，您将获得</div>
                    <button className={styles.findMore} onClick={moreClick}>{`了解更多>`}</button>
                </div>
                <div className={styles.rightColumn}>
                    <img src={doctorServiceImg} alt="医疗健康服务图片" />
                </div>
            </div>
        </div>
    );
}

export default DoctorService;
